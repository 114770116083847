.about {
    flex-direction: column;
    margin-top: 3em;
  }
  
  .about__name {
    color: var(--clr-primary);
  }
  
  .about__role {
    margin-top: 1.2em;
  
  }
  
  .about__edu {
    margin-top: 2.4em;
    font-size: 1.2rem;
    max-width: 600px;
  }
  
  .about__desc {
    font-size: 1rem;
    max-width: 600px;
    margin-top: 1em;
  }
  
  .about__contact {
    margin-top: 2.4em;
  }
  
  .about .link--icon {
    margin-right: 0.8em;
  }
  
  .about .btn--outline {
    margin-right: 1em;
  }
  
  @media (max-width: 600px) {
    .app .about {
      /* align-items: flex-start; */
      margin-top: 2em;
    }
  }
  
  .typewriter {
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: .15em solid orange; /* The typwriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    /* letter-spacing: .15em;  */
    animation: 
      typing 3.5s steps(40, end),
      blink-caret .75s step-end infinite;
  }
  
  /* The typing effect */
  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  
  /* The typewriter cursor effect */
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: orange; }
  }